<template>
  <NavigationComp :isDisable="false" />
  <main class="news container">
    <article class="news__item" v-for="item in news" :key="item.id" :id="item.alias">
      <h2 class="news__title">{{ item.title }}</h2>
      <div class="news__img-list">
        <img v-for="(img, index) in item.poster" :key="index" :src="img" :alt="item.title" />
      </div>
      <div class="news__textContent" v-html="item.content"></div>
      <div class="news__img-list">
        <img
          v-for="(img, index) in item.images"
          :key="index"
          :src="img"
          :alt="item.title"
          @click="openImage(img)"
          class="thumbnail"
        />
      </div>
      <div v-if="isModalOpen" class="modal" @click="closeImage">
        <img :src="selectedImage" alt="Full Image" class="full-image" />
      </div>
      <div class="news__video-list">
        <video v-for="(video, index) in item.videos" :key="index" :src="video" controls></video>
      </div>
    </article>
  </main>
</template>

<script>
import NavigationComp from '@/components/UI/Navigation/NavigationComp.vue';
import { mapState } from 'vuex';
import { useHead } from '@vueuse/head';

export default {
  components: { NavigationComp },
  name: 'Новости',
  computed: {
    ...mapState({
      news: (state) => state.NewsStore.news,
    }),
  },

  data() {
    return {
      selectedImage: null,
      isModalOpen: false,
    };
  },

  methods: {
    openImage(img) {
      this.selectedImage = img;
      this.isModalOpen = true;
    },
    closeImage() {
      this.isModalOpen = false;
      this.selectedImage = null;
    },
  },

  setup() {
    useHead({
      title:
        'Новости | Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»',
      meta: [
        {
          name: 'description',
          content: 'Новости Центра гигиены и эпидемиологии в Запорожской области.',
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  display: flex;
  gap: 10px;
}
.thumbnail {
  cursor: pointer;
  width: 450px;
  object-fit: cover;
  transition: transform 0.2s;
}
.thumbnail:hover {
  transform: scale(1.1);
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.full-image {
  max-width: 90%;
  max-height: 90%;
}
</style>
