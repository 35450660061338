import { v4 as uuidv4 } from 'uuid';
import PROFORIENT from '@/assets/documents/ПРОФОРИЕНТ.pdf';

const NewsStore = {
  state: {
    news: [
      {
        id: uuidv4(),
        title: 'Профилактика ВИЧ-инфекций',
        alias: 'vich2025',
        content: `
				<h4 class="news__subtitle">Что такое ВИЧ?</h4>
					<p class="news__text--indent">
						ВИЧ (Вирус Иммунодефицита Человека) - один из самых опасных для человека вирусов. Он поражает иммунную систему, основная задача которой - защищать наш организм от инфекций.
						</p>    
						<br /> 
						<p class="news__text--indent">
						Через несколько недель после инфицирования развивается симптоматика болезни - повышается температура, увеличиваются лимфатические узлы, появляются боль в горле, красные пятна на коже, понос. Непонятное недомогание быстро проходит, а иногда слабовыраженные признаки болезни и вовсе остаются незамеченными.
Несколько лет вирус ведет "тихую" жизнь, не "досаждая" человеку. Но все это время он неустанно разрушает иммунную систему, размножаясь за счет ее основных клеток - лимфоцитов. Внешне ВИЧ-инфекция может проявляться только увеличением лимфатических узлов.
						</p>
						<br /> 
						<h4 class="news__subtitle">В чем разница между ВИЧ-инфекцией и СПИДом?</h4>
						<p class="news__text--indent">
						СПИД (Синдром Приобретенного Иммунодефицита) - это конечная и самая тяжелая стадия развития ВИЧ-инфекции. Разрушительное влияние, которое ВИЧ оказывает на иммунную систему человека в течение нескольких лет, приводит к развитию иммунодефицита. А это означает, что любые инфекции, вирусы и болезни больше не встречают "отпора" на своем пути, и организм уже не в силах бороться с ними. У больного СПИДом развивается множество тяжелых болезней, от которых он в конечном итоге погибает.
						</p>
						<br /> 
						<h4 class="news__subtitle">Существует ли лекарство от СПИДа?</h4>
						<p class="news__text--indent">
						Средняя продолжительность жизни ВИЧ-инфицированного, при отсутствии лечения, составляет 5-10 лет. И хотя "чудодейственная" вакцина против ВИЧ и СПИДа пока не найдена, исследования в этом направлении идут быстрыми темпами и достаточно успешно. Уже сейчас существуют лекарственные препараты, которые подавляют размножение вируса, не дают болезни прогрессировать и не допускают перехода ВИЧ-инфекции в стадию СПИДа. Многие больные, начавшие лечение 15 назад, когда были открыты эти препараты, и сегодня чувствуют себя вполне работоспособными. Лечащие врачи дают весьма оптимистичные прогнозы по поводу продолжительности их жизни.
						</p>
						<br /> 
						<h4 class="news__subtitle">Кто подвержен риску ВИЧ-инфицирования?</h4>
						<p class="news__text--indent">
						В обществе распространено мнение, что основные "поставщики" ВИЧ - это люди, практикующие рискованный образ жизни: мужчины с нетрадиционной сексуальной ориентацией, потребители инъекционных наркотиков, лица, ведущие беспорядочную половую жизнь. Однако за последние годы "лицо" ВИЧ-эпидемии сильно изменилось. Во всем мире, в том числе и в России, преобладающим стал гетеросексуальный путь передачи ВИЧ. Потребителей инъекционных наркотиков и людей с нетрадиционной сексуальной ориентацией среди инфицированных становится все меньше, а вот зараженных при гетеросексуальных контактах - все больше. Угрожающе быстро растет количество ВИЧ-инфицированных женщин. Как следствие - резкое увеличение числа детей, рожденных ВИЧ-инфицированными матерями.
						</p>
						<br /> 
						<h4 class="news__subtitle">Как передается ВИЧ-инфекция?</h4>
						<p class="news__text--indent">
						У ВИЧ-инфицированного человека концентрация вируса наиболее высока в крови, лимфе, сперме, вагинальном секрете и грудном молоке. Поэтому ВИЧ-инфекцией можно заразиться:
						</p>
						<br /> 
						<ul class="news__ulList">
							<li class="news__ulList--padding">при половых контактах без использования презерватива;</li>
							<li class="news__ulList--padding">при использовании шприца (иглы, раствора), которым пользовался ВИЧ-инфицированный;</li>
							<li class="news__ulList--padding">при переливании заражённой крови;</li>
							<li class="news__ulList--padding">при родах - ребёнок может заразиться от матери;</li>
							<li class="news__ulList--padding">при кормлении ребенка грудью, если мать – носитель вируса.</li>
						</ul>		
						<br /> 
						<p class="news__text--indent">
						В слезах, слюне, поте, моче, рвотных массах, выделениях из носа ВИЧ содержится в очень низкой, недостаточной для заражения концентрации.
						</p>
						<br /> 
						<h4 class="news__subtitle">Как НЕ передается ВИЧ-инфекция?</h4>
						<br /> 
						<ul class="news__ulList">
							<li class="news__ulList--padding">при рукопожатии и прикосновении;</li>
							<li class="news__ulList--padding">при поцелуе;</li>
							<li class="news__ulList--padding">при пользовании одной посудой;</li>
							<li class="news__ulList--padding">при кашле или чихании;</li>
							<li class="news__ulList--padding">через постельное бельё или другие личные вещи;</li>
							<li class="news__ulList--padding">при пользовании общественным туалетом;</li>
							<li class="news__ulList--padding">через укусы насекомых.</li>
						</ul>		
						<br /> 
						<h4 class="news__subtitle">Как избежать заражения ВИЧ?</h4>
						<p class="news__text--indent">
						Несмотря на все большее расширение эпидемии ВИЧ, заражения можно избежать. Правила профилактики просты, но надежны. Чтобы полностью предохранить себя от заболевания, достаточно:
						</p>
						<br /> 
						<ul class="news__ulList">
							<li class="news__ulList--padding">пользоваться презервативами при половых контактах;</li>
							<li class="news__ulList--padding">пользоваться стерильными медицинскими инструментами.</li>
						</ul>		
						<br /> 
						<p class="news__text--indent">
						Вероятность рождения здоровых детей у ВИЧ-инфицированных матерей значительно возрастает, если во время беременности женщины проходят лечение.
						</p>
						<br /> 
						<h4 class="news__subtitle">ВИЧ очень опасен, НО его можно избежать!!!</h4>
						<br /> 
						<h4 class="news__subtitle">Профилактика ВИЧ-инфекции</h4>
						<br /> 
						<p class="news__text--indent">
						В настоящее время ведутся интенсивные поиски эффективной вакцины против ВИЧ. Однако быстрая изменчивость вируса затрудняет разработку вакцины для специфической профилактики.
						</p>
						<br /> 
						<p class="news__text--indent">
						Единственное действенное средство предупреждения распространения ВИЧ – инфекции на сегодняшний день является просвещение населения (и подростков и взрослое население) по проблеме.
						</p>
						<br /> 
						<p class="news__text--indent">
						Одним из основных аспектов профилактики ВИЧ–инфекции является пропаганда здорового образа жизни. Основа здорового образа жизни - ежедневная физическая активность, рациональное питание, закаливание организма, полноценный отдых, профилактика вредных привычек и стрессовых состояний.
						</p>
						<br /> 
						<p class="news__text--indent">
						Второе направление профилактической работы - это информирование молодых людей о главном принципе эффективной профилактики ВИЧ-инфекции, который заключается в прерывании путей передачи вируса, т.е. отсутствие в поведении ситуаций, связанных с риском инфицирования ВИЧ.
						</p>
						<br /> 
						<p class="news__text--indent">
						ВИЧ передается только в тех случаях, когда содержащие вирус жидкости тела (кровь, семенная жидкость, вагинальный секрет) проникают в организм здорового человека. Очень часто это происходит при сексуальном контакте.
						</p>
						<br /> 
						<p class="news__text--indent">
						Безопасный секс – это отношения между партнёрами, когда проникновения и обмена жидкостями организма (опасными в плане заражения ВИЧ) не происходит (петтинг, поцелуи, объятия, ласки, массаж и т.д.).
						</p>
						<br /> 
						<p class="news__text--indent">
						Защищенный секс - это отношения между партнёрами, когда при проникающих сексуальных контактах используется барьерный метод защиты - презерватив, который дает 98 % защиты!
						</p>
						<br /> 
						<p class="news__text--indent">
						Путь передачи ВИЧ через кровь в настоящее время реализуется в среде наркопотребителей, которые при групповом использовании внутривенных наркотиков применяют один общий шприц, иглу, емкость для забора наркотиков и фильтр. Поэтому, если человек воздерживается от потребления наркотиков, значит от этого пути передачи ВИЧ он защищён.
						</p>
						<br /> 
						<p class="news__text--indent">
						Не следует забывать и о том, что заражение ВИЧ возможно при следующих манипуляциях: при проколе ушей, нанесении татуировок, при пользовании чужими опасными бритвами. Важным моментом предупреждения инфицирования в данном случае, является использование одноразового инструментария или качественная его обработка, которая предотвращает возможность заражения вирусом. Безопасными эти манипуляции будут считаться, если они проводятся в лицензированных салонах.
						</p>
						<br /> 
						<p class="news__text--indent">
						Профилактика ещё одного пути передачи ВИЧ – от матери к ребёнку сводится к мероприятиям, направленным на уменьшение факторов, которые повышают риск передачи вируса от инфицированной матери к ребёнку во время беременности, родов и вскармливания грудью. Прежде всего, это противовирусное лечение беременной женщины, максимальное сокращение продолжительности родов, назначение противовирусного лечения новорожденному, искусственное вскармливание младенца.
						</p>
						<br /> 
						<h4 class="news__subtitle">НЕКОТОРЫЕ СОВЕТЫ, КОТОРЫЕ ПОЛЕЗНО ЗНАТЬ</h4>
						<br /> 
						<ul class="news__ulList">
							<li class="news__ulList--padding">Берегите ваше здоровье и счастье тех, кого вы любите.</li>
							<li class="news__ulList--padding">Оставайтесь верным одному сексуальному партнеру, который, в свою очередь, верен вам.</li>
							<li class="news__ulList--padding">Если вы не уверены в прошлом вашего сексуального партнера, подумайте о своем настоящем и будущем.</li>
							<li class="news__ulList--padding">Любой наркотик или алкоголь может притупить вашу бди¬тельность и привести к принятию «рискованного решения», чреватого заражением ВИЧ.</li>
							<li class="news__ulList--padding">Всегда имейте при себе презервативы и не рассчитывайте, что они окажутся у вашего партнера, однако, помните: презервативы снижа¬ют, но не исключают риск заражения.</li>
							<li class="news__ulList--padding">Помните, что сохраненное на долгие годы здоровье - это самый ценный капитал, который не сравним с самым острым (но все же крат¬ковременным) сексуальным удовольствием.</li>
							<li class="news__ulList--padding">В настоящее время эффективных препаратов для лечения СПИДа не существует.</li>
						</ul>		
						<br /> 
						<p class="news__text--indent">
						Знайте, за распространение венерических болезней и ВИЧ- инфекции, привлекают к уголовной ответственности.
						</p>
				`,
        images: [require('@/assets/img/news/11/img1.jpg')],
        poster: [require('@/assets/img/news/11/poster.jpg')],
      },
      {
        id: uuidv4(),
        title: 'О заболеваемости ОРИ гриппом в Запорожской области',
        alias: 'orvi2025',
        content: `
					<p class="news__text--indent">
						Острая респираторная инфекция (ОРИ) - воспаление верхних дыхательных путей, которое сопровождается лихорадкой, насморком, чиханием, кашлем, болью в горле, нарушением общего состояния разной выраженности.
						</p>    
						<br /> 
						<p class="news__text--indent">
						В Запорожской области в 2025 году зарегистрированы более одной тысячи случаев ОРИ. Иными словами, из каждых 10 тысяч населения заболели почти 30 человек. Самая высокая заболеваемость в г. Мелитополь.
						</p>
						<br /> 
						<p class="news__text--indent">
						Также зарегистрированы 6 случаев гриппа, подтвержденных лабораторно.
						</p>
						<br /> 
						<p class="news__text--indent">
						На прошедшей 3-й неделе 2025 года в Запорожской области уровень заболеваемость ОРИ и гриппом не превышает пороговых значений, то есть эпидемии нет.
						</p>
						<br /> 
						<p class="news__text--indent">
						В структуре циркулирующих вирусов преобладают респираторные вирусы: риновирус, сезонный коронавирус, парагрипп.
						</p>
						<br /> 
						<p class="news__text--indent">
						В период подъема заболеваемости ОРИ и гриппом необходимо соблюдать меры неспецифической профилактики, в том числе:
						</p>
						<br /> 
						<ul class="news__ulList">
							<li class="news__ulList--padding">предотвращать контакт с людьми, у которых есть признаки заражения;</li>
							<li class="news__ulList--padding">соблюдать правила гигиены и регулярно мыть руки, использовать антисептик после посещения мест массового скопления людей и общественного транспорта, не касаться слизистых глаз и носа;</li>
							<li class="news__ulList--padding">взять за правило респираторный этикет: соблюдать дистанцию при разговоре, при нахождении в общественных местах использовать маски или респираторы;</li>
							<li class="news__ulList--padding">поддерживать иммунитет и вести здоровый образ жизни, больше находиться на свежем воздухе, одеваться по погоде, правильно питаться, заниматься спортом.</li>
						</ul>		
						<br /> 
						<p class="news__text--indent">
						Эпидемиологическая ситуация по ОРИ и гриппу находится на постоянном контроле санитарно-эпидемиологической службы Запорожской области.
						</p>
						<br /> 
						<p class="news__text--indent">
						Заведующая отделом эпидемиологии ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области»                                                                   Т.А. Симонова
						</p>
						<br /> 
				`,
        images: [require('@/assets/img/news/10/img1.jpg'), require('@/assets/img/news/10/img2.jpg')],
        poster: [require('@/assets/img/news/10/img1.jpg')],
      },
      {
        id: uuidv4(),
        title: 'С Новым годом и Рождеством!',
        alias: 'ny2025',
        content: '',
        images: [],
        poster: [require('@/assets/img/news/ny2025.jpg')],
      },
      {
        id: uuidv4(),
        title: 'Целевое обучение в образовательные организации Минздрава России',
        alias: 'edu',
        content: `<p class="news__text--indent">Целевое обучение (ЦО) — это форма получения образования, при которой абитуриент еще до поступления в образовательное учреждение (высшее или среднее профессиональное) заключает договор с работодателем. Документ дает человеку возможность бесплатно получить образование (за счет организации), иногда даже получать дополнительную стипендию, а после — гарантированно трудоустроиться. В свою очередь, студент обязуется пройти обучение по выбранной программе, обозначенной в документе, и, соответственно, вернуть организации вложенные в него средства в формате работы на нее в течение трех-пяти лет.</p>
				<br />
				<p class="news__text--indent">Скачать документ в формате PDF: <a href="${PROFORIENT}" target="_blank">ПРОФОРИЕНТ</a></p>
					`,
        images: [require('@/assets/img/content/proforient1.png'), require('@/assets/img/content/proforient2.png')],
        poster: [require('@/assets/img/news/edu.png')],
      },
      {
        id: uuidv4(),
        title: 'Экологический диктант',
        alias: 'ekodictant',
        content: `
				<p class="news__text--indent">
					Федеральная служба по надзору в сфере защиты прав потребителей и благополучия человека в соответствии с письмом Комитета Совета Федерации Федерального Собрания Российской Федерации по аграрно-продовольственной политике и природопользованию о Всероссийском экологическом диктанте (информация размещена на интернет-портале https://экодиктант.рус), запланированном в период с 25 ноября по 8 декабря 2024 года, предлагает оказать информационную поддержку в проведении Всероссийского экологического диктанта с размещением информации на официальных сайтах территориальных органов и подведомственных организаций Роспотребнадзора.
				</p>
				`,
        images: [],
        poster: [require('@/assets/img/news/ecodiktant.png')],
      },
      {
        id: uuidv4(),
        title: 'Внимание, ГРИПП',
        alias: 'gripp',
        content: '',
        images: [],
        poster: [require('@/assets/img/news/gripp.jpg')],
      },
      {
        id: uuidv4(),
        title:
          'Информация для выпускников общеобразовательных учреждений и желающих получить медицинское образование в ВУЗах Российской Федерации ',
        alias: 'target-education',
        content: `
						<p class="news__text--indent">
						Управление Федеральной службы по надзору в сфере защиты прав потребителей и благополучия человека по Запорожской области (далее- Управление Роспотребнадзора) информирует о том, что Управлению Роспотребнадзора и его подведомственному учреждению ФБУЗ "Центр гигиены и эпидемиологии в Запорожской области" выделяются целевые места для подготовки специалистов по направлению 32.05.01 «Медико-профилактическое дело» за счет бюджетных ассигнований федерального бюджета на места в пределах квоты на целевой прием в медицинские высшие учебные заведения Российской Федерации.
						</p>    
						<br /> 
						<p class="news__text--indent">
						В рамках целевого обучения выделены квоты в количестве 12 мест в следующие образовательные учреждения:
						</p>
						<br />
						<table class="news__table">
			<thead>
				<tr>
					<th class="news__table-th">Наименование территориального органа /подведомственного учреждения Роспотребнадзора</th>
					<th class="news__table-th">Распределение мест целевого обучения по образовательным организациям</th>
					<th class="news__table-th">Квота приема</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="news__table-td news__table-td--weight">Управление Роспотребнадзора по Запорожской области</td>
					<td class="news__table-td">Управление Роспотребнадзора по Запорожской области	ФГБОУ ВО "Волгоградский государственный медицинский университет" Минздрава России</td>
					<td class="news__table-td">1</td>
				</tr>
				<tr>
					<td class="news__table-td news__table-td--weight">Управление Роспотребнадзора по Запорожской области</td>
					<td class="news__table-td">ФГБОУ ВО "Ростовский государственный медицинский университет" Минздрава России</td>
					<td class="news__table-td">1</td>
				</tr>
				<tr>
					<td class="news__table-td news__table-td--weight">Управление Роспотребнадзора по Запорожской области</td>
					<td class="news__table-td">ФГБОУ ВО "Северо-Западный государственный медицинский университет им. И.И. Мечникова" Минздрава России</td>
					<td class="news__table-td">4</td>
				</tr>
				<tr>
					<td class="news__table-td news__table-td--weight">ФБУЗ "Центр гигиены и эпидемиологии в Запорожской области"</td>
					<td class="news__table-td">ФГБОУ ВО "Волгоградский государственный медицинский университет" Минздрава России</td>
					<td class="news__table-td">2</td>
				</tr>
				<tr>
					<td class="news__table-td news__table-td--weight">ФБУЗ "Центр гигиены и эпидемиологии в Запорожской области"</td>
					<td class="news__table-td">ФГБОУ ВО "Ростовский государственный медицинский университет" Минздрава России</td>
					<td class="news__table-td">1</td>
				</tr>
				<tr>
					<td class="news__table-td news__table-td--weight">ФБУЗ "Центр гигиены и эпидемиологии в Запорожской области"</td>
					<td class="news__table-td">ФГБОУ ВО "Северо-Западный государственный медицинский университет им. И.И. Мечникова" Минздрава России</td>
					<td class="news__table-td">3</td>
				</tr>
			</tbody>
		</table>
						<br />
						<p class="news__text--indent">
						С гражданином, изъявившем желание получить квоту, будет заключен договор о целевом обучении. После окончания ВУЗа - работа в органах Роспотребнадзора и подведомственных ему учреждениях на территории Запорожской области).  
						</p>
						<br />
						<p class="news__text--indent">
						Для получения подробной информации необходимо обращаться в Управление Роспотребнадзора по Запорожской области – г. Мелитополь, ул. Сухова, д. 6, 2 этаж, каб. 25 или по телефону +7 990-290-25-21.
						</p>
				`,
        images: [],
        poster: [require('@/assets/img/news/7_target-education.webp')],
      },
      {
        id: uuidv4(),
        title: 'ОСТОРОЖНО КЛЕЩИ – ПЕРЕНОСЧИКИ ВОЗБУДИТЕЛЕЙ ОПАСНЫХ ИНФЕКЦИОННЫХ ЗАБОЛЕВАНИЙ!!!',
        alias: 'acarus',
        content: `
						<h4 class="news__title">Памятка для детей и взрослых</h4>
						<p class="news__text--indent">
						На территории Запорожской области проснулись клещи, которые могут быть переносчиками опасных инфекций, как например, клещевого вирусного энцефалита, иксодовых клещевых боррелиозов, моноцитарного эрлихиоза человека, гранулоцитарного анаплазмоза человека, ку-лихорадки, Конго-Крымской геморрагическая лихорадки, туляремии и др. Активными они становятся уже при температуре +5 градусов, а пик в нашем регионе приходится на май и сентябрь.
						</p>     
						<br />
						<h4 class="news__subtitle">УКУС КЛЕЩА</h4>
						<p class="news__text--indent">
						В лесу, лесопарковой зоне клещи концентрируются вдоль троп и, находясь на ветках или траве, при приближении животного или человека могут прицепиться. Присасывание членистоногих наблюдается, чаще всего в области шеи, волосистой части головы, спины, подмышечных впадин и паховой области и др. В слюне клеща находится обезболивающее вещество, поэтому человек или животное не чувствует укус. При его обнаружении важно как можно быстрее извлечь, так как чем дольше он находится на теле, тем выше вероятность передачи возбудителя инфекции. 
						</p>
						<p class="news__text--indent">
						Удалить паразита можно пинцетом или пальцами, обернув их чистой марлей, ухватив членистоногое как можно ближе к голове, приподнять, прокрутить несколько раз и потянуть вверх. Затем его помешают в емкость (пузырек, пробирка, маленькая банка, одноразовый маленький контейнер и т.д.) с влажной ватой, тканью или травинками для создания влажности, закрывают крышкой и отправляют в лабораторию, чтобы проверить, не был ли он переносчиком возбудителя инфекционного заболевания. Лабораторное исследование клещей методом ПЦР проводится во ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области», г. Мелитополь, ул. Сухова 6. В случае отсутствия возможности доставки живого клеща в лабораторию в течение 3 суток– клеща нужно сжечь или залить кипятком. Место укуса и руки обязательно необходимо обработать аптечным раствором 70 % этилового спирта или 3 % перекиси водорода или 1 % хлоргексидина. Руки вымыть с мылом. 
						</p>
						<p class="news__text--indent news__text--red news__text--center">
						Насторожиться следует, если на месте укуса клеща возникло овальное или округлое пятно с постепенно проступающей каймой ярко-красного цвета и /или появились озноб, сонливость, упадок сил, светобоязнь, боль в суставах, температура, зуд, снижение артериального давления, учащенное сердцебиение, увеличение лимфатических узлов. В этом случае нужно обязательно показаться врачу. 
						</p>
						<br />
						<h4 class="news__subtitle">Профилактика</h4>
						<p class="news__text--indent">
						На природе лучше находиться в головном уборе и в закрытой одежде. Не ходить по высокой траве в открытой одежде. Также на одежду и кожу рекомендуется наносить репелленты против клещей. После прогулки необходимо внимательно осмотреть все тело и вещи перед тем, как зайти в помещение.
						</p>
						<br />
						<h4 class="news__subtitle">Порядок проведения лабораторных исследований клещей во ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области»</h4>
						<p class="news__text--indent">
						Снятого клеща необходимо поместить в емкость (пузырек, пробирка, маленькая банка, одноразовый маленький контейнер и т.д.) с влажной ватой, тканью или травинками для создания влажности, закрывают крышкой и как можно скорее отправляют в лабораторию. К исследованиям принимаются клещи, доставленные в лабораторию в течение 3 дней от момента снятия. Срок исполнения исследований – от 2 до 4-х рабочих дней (не включая дату сдачи материала). Результат исследования (протокол) можно получить в электронном виде по электронной почте на личный e-mail заказчика или в бумажном виде на руки, обратившись в ФБУЗ Центр гигиены и эпидемиологии в Запорожской области, г. Мелитополь, ул. Сухова 6. О стоимости исследований можно ознакомиться на сайте в разделе Услуги.
						</p>
						<br />
						<h4 class="news__subtitle">АКАРИЦИДНЫЕ ОБРАБОТКИ В СЕЗОН АКТИВНОСТИ КЛЕЩЕЙ</h4>
						<p class="news__text--indent">
						Борьбу с популяциями клещей в природных биотопах проводят в зонах высокого риска заражения населения с помощью инсектоакарицидных (акарицидных) средств, прошедших процедуру государственной регистрации. С учетом значимости приусадебных, дачных и садовых участков как мест контакта населения с клещами в настоящее время ряд средств разрешены для применения населением для самостоятельной обработки участков, находящихся в личном пользовании, в соответствии с текстами этикеток для быта. Список инсектоакарицидных средств, разрешенных для применения с целью уничтожения иксодовых клещей в природных биотопах, размещен в Реестре свидетельств о государственной регистрации (в рамках ТС ЕврАзЭС) по адресу http://fp.crc.ru/.  Средства, разрешенные для применения населением в быту для самостоятельной обработки участков, находящихся в личном пользовании, отмечены особо (в разделе «Примечание»). 
						</p>
						<p class="news__text--indent">
						Результаты обследования на 3–5 сутки после обработки являются основанием для решения вопроса о безопасности пребывания людей на данной территории, а в более поздние сроки — о возможной необходимости повторной обработки (при недостаточной эффективности). На территориях детских учреждений (например, школ и лагерей) противоклещевые обработки являются обязательным условием пребывания там детей.  
						</p>
						<p class="news__text--indent">
						Современные акарицидные препараты сохраняются в верхних слоях лесной подстилки до полутора месяцев и уничтожают активную часть популяции, не затрагивая особей из нижних слоев. Противоклещевые обработки каждой территории должны быть ежегодными, а в ряде случаев необходимо несколько обработок в течение сезона. Это позволит надежно защитить людей, находящихся на опасных в отношении клещей территориях. 
						</p>
						<p class="news__text--indent">
						ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области» предоставляет услуги по проведению акарицидной обработки территории, стоимость которой представлена в разделе Услуги.
						</p>
						<br />
						<h4 class="news__subtitle">Порядок проведения акарицидных обработок во ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области»</h4>
						<p class="news__text--indent">
						В отделе эпидемиологии ФБУЗ «Центр гигиены и эпидемиологии в Запорожской области» по адресу: г. Мелитополь, ул. Сухова 6 можно получить бесплатную консультацию и оставить заявку на проведение акарицидной обработки. Расчет стоимости услуги проводится с учетом площади территорий (в сотках). Важным условием перед проведением акарицидной обработки является обеспечение Заказчиком удовлетворительного санитарного содержания территории, включающее стрижку газонов, уборку листвы и сухой травы, сухостоя, хозяйственного и бытового мусора, а также расчистка прилегающих территорий от мусора, валежника, сухостоя в радиусе 50-100 м вокруг принадлежащего участка. Необходимо предусмотреть энтомологическое (акарологическое) обследование территории до акарицидной обработки с целью определения видового состава и численности иксодовых клещей и повторное энтомологическое обследование для контроля эффективности обработки.   
						</p>		
				`,
        images: [require('@/assets/img/news/6/6_acarus.jpg'), require('@/assets/img/news/6/6_acarus2.webp')],
        poster: [require('@/assets/img/news/6/6_acarus.jpg')],
      },
      {
        id: uuidv4(),
        title: 'Уважаемые Коллеги!',
        alias: 'Kimovich',
        content: `
						<p class="news__text--indent">
						От себя лично и от коллективов Управления Роспотребнадзора по Запорожской области и «ФБУЗ Центр гигиены и эпидемиологии в Запорожской области» выражаю Вам глубокие соболезнования в связи со скоропостижной смертью <strong>Алексея Кимовича Носкова</strong>, директора ФКУЗ Ростовский-на-Дону противочумный институт Роспотребнадзора.
						</p>     
						<br />
						<p class="news__text--indent">
						Ушел из жизни талантливый человек, опытный специалист и профессионал своего дела. Алексей Кимович обладал глубокими знаниями и имел большой практический опыт в обеспечении санитарно-эпидемиологического благополучия населения в мирное время и в вооруженных конфликтах; при организации спасательных мероприятий при ликвидации последствий землетрясения, паводка, опасных метеорологических явлений и в период пандемии. Будучи руководителем института, внес весомый вклад в обеспечение санитарно-эпидемиологического благополучия населения страны, совершенствование эпидемиологического надзора за холерой и другими особо опасными и природно-очаговыми инфекционными болезнями, их лабораторную диагностику и профилактику. 
						</p>
						<br />
						<p class="news__text--indent">
						Алексей Кимович имел заслуженный высокий авторитет в системе Роспотребнадзора и здравоохранения. Его неравнодушие и преданность своему делу восхищали коллег и предопределяли легкость и результативность при взаимодействии по всем вопросам. 
						</p>
						<br />
						<p class="news__text--indent">
						Скорбим вместе с Вами!
						</p>
						<br />
						<p class="news__text--indent">
						Руководитель Управления 
						</p>
						<br />
						<p class="news__text--indent">
						Роспотребнадзора по Запорожской области  <span class="news__text--right">Н.В. Хаттатова</span>
						</p>
				`,
        images: [],
        poster: [require('@/assets/img/news/5_noskov-black.jpg')],
      },
      {
        id: uuidv4(),
        title: 'О профилактике холеры',
        alias: 'holera',
        content: `
						<p class="news__text--indent">
						Холера - острая инфекционная болезнь с клиникой кишечной инфекции, которая регистрируется только у людей, как взрослых, так и у детей. Тяжелые случаи связаны с обезвоживанием организма из-за водянистой диареи и рвоты. Возбудитель - холерный вибрион. После заражения клинические проявления чаще возникают в течение 5 дней. Источник инфекции - больной человек, у которого может не быть симптомов заболевания.
						</p>     
						<br />
						<p class="news__text--indent">
						Пути передачи инфекции - водный, пищевой и контактно-бытовой, когда возбудитель из кишечника больного при несоблюдении правил личной гигиены инфицирует воду, пищу, предметы обихода и попадает в организм здорового человека.
						</p>
						<br />
						<p class="news__text--indent">
						Холерой можно заразиться при питье воды или употреблении в пищу продуктов, зараженных возбудителем. Чаще всего источниками инфекций пищевого происхождения являются сырые или не прошедшие достаточную тепловую обработку морепродукты, свежие фрукты и овощи, а также другие продукты, зараженные во время их приготовления или хранения.
						</p>
						<br />
						<p class="news__text--indent">
						Профилактика холеры связана с потреблением чистой питьевой воды и своевременной очисткой сточных вод, соблюдением правил гигиены, использованием чистой воды для мытья продуктов питания и посуды, безопасным приготовлением и хранением пищи.
						</p>
						<br />
						<p class="news__text--indent">
						В Запорожской области осуществляется санитарно-эпидемиологический контроль за водоснабжением, канализацией, очисткой населенных мест. На территории Запорожской области проводится плановый лабораторный контроль по выявлению холерных вибрионов в воде поверхностных водоемов и других объектах окружающей среды.
						</p>
						<br />
						<p class="news__text--indent news__text--bold">
						Населению рекомендуется ответственно относиться к своему здоровью, не заниматься самолечением, при первых признаках кишечной инфекции своевременно обращаться за медицинской помощью.
						</p>
				`,
        images: [],
        poster: [require('@/assets/img/news/4_holera.webp')],
      },
      {
        id: uuidv4(),
        title: 'О проведении «горячей линии» по актуальным вопросам защиты прав потребителей в судебном порядке',
        alias: 'hotline',
        content: `
						<p class="news__text--indent">
						Управление Роспотребнадзора по Запорожской области и Федеральное бюджетного учреждения здравоохранения «Центр гигиены и эпидемиологии в Запорожской области» информирует, что в период с 11 по 22 марта 2024 года проводится «горячая линия» по актуальным вопросам защиты прав потребителей в судебном порядке. 
						</p>     
						<br />
						<p class="news__text--indent">В период проведения специалисты Управления проконсультируют граждан о правах потребителей, расскажут, что делать в случае неурегулирования спорной ситуации между продавцом и покупателем, об изменениях в проведении надзорных мероприятий в сфере защиты прав потребителей, ответят на вопросы, касающиеся реализации закона о защите прав потребителей, порядке и способах судебной защиты прав и законных интересов, помогут в оформлении претензий, исковых заявлений в судебные органы, а также дадут ответы на другие актуальные вопросы. </p>
						<br />
						<p class="news__text--indent">За консультацией можно обратиться по телефону «горячей линии» +7 990-234-50-40, а также на личном приеме специалистов по адресу: Запорожская область, г. Мелитополь, ул. Сухова, д. 6.</p>
						<br />
						<p class="news__text--indent">Часы работы горячей линии: Понедельник - Четверг: 09:00 - 17:00; Пятница: 09:00 - 16:45; Перерыв: 13:00 - 13:45.</p>
				`,
        images: [],
        poster: [require('@/assets/img/news/3_protection.webp')],
      },
      {
        id: uuidv4(),
        title:
          'Специалисты Роспотребнадзора из Запорожской области начали работу над исследованием различных материалов и проб',
        alias: 'startWork',
        content: `
						<p class="news__text">
						Перед этим сотрудники ведомства прошли обучение и получили комплексные практические навыки, которые помогут им оставаться на страже здоровья жителей  региона.
						<br />
						<br />
						Смотрите За!ТВ  <a href="https://vk.com/za_tv_ru">ВКонтакте</a> и <a href="https://t.me/+ytSQE-ESZuxhZDAy">Telegram</a>
						</p>
				`,
        videos: [require('@/assets/videos/videonew_6.mp4')],
        images: [],
        poster: [require('@/assets/img/news/2_news.jpg')],
      },
      {
        id: uuidv4(),
        title: 'Специалисты Роспотребнадзора из новых регионов РФ прошли обучение работе в мобильной лаборатории',
        alias: 'finishEducation',
        content: `				
						<p class="news__text">
						С 27 по 30 ноября на базе Управления Роспотребнадзора по Запорожской области состоялись учения по отработке теоретических и практических занятий по подготовке специалистов для работы в мобильной лаборатории на базе автошасси. Сотрудники Российского противочумного института «Микроб» Роспотребнадзора обучили особенностям работы в передвижной лаборатории 13 сотрудников управлений Роспотребнадзора из Донецкой и Луганской Народных Республик, Запорожской и Херсонской областей. 
						</p>
						<ul class="news__ulList">
							<h4 class="news__ulList-subtitle">Обучение включило в себя: </h4>
							<li class="news__ulList--padding">знакомство с правилами работы с патогенными биологическими агентами 2-4 групп патогенности в соответствии с требованиями биологической безопасности;</li>
							<li class="news__ulList--padding">обсуждение вопросов работы со средствами индивидуальной защиты, используемыми при исследовании возбудителей особо опасных инфекций;</li>
							<li class="news__ulList--padding">объяснение нюансов манипуляций при подготовке проб биологического материала и объектов внешней среды, их отбору, упаковке, транспортировке и условиям хранения. </li>
							<li class="news__ulList--padding">практическая работа на современном лабораторном оборудовании. </li>
						<p class="news__text news__text--marginTop">
							Специалисты получают комплексные практические навыки по работе с ПЦР и ИФА, которые позволят организовать своевременную диагностику инфекционных заболеваний, в том числе идентификацию возбудителей особо опасных инфекций.
						</p>
						</ul>				
				`,
        images: [require('@/assets/img/news/1/pic1.webp'), require('@/assets/img/news/1/pic2.webp')],
        poster: [require('@/assets/img/news/1/pic1.webp')],
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};

export default NewsStore;
